import React, { Component } from "react";

class Footer extends Component {
  state = {};
  render() {
    return (
      <section id="contact" className="footer text-center">
        <div className="container">
          <div className="justify-content-md-center row mt-2">
            <div className="col-md-5">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <a className="footer-link" href="#policy">
                  Privacy Policy
                </a>
                <a className="footer-link" href="#terms">
                  Terms
                </a>
              </div>
              <p className="mt-3"> Liam Dwyer &copy; 2023</p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Footer;
