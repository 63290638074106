import React, { Component } from "react";
import logo from "../../images/strava_logo.png";
import sheet from "../../images/sheet_icon.png";
import watch from "../../images/watch.png";

import "../../style.css";

class ExplainationWeb extends Component {
  state = {};
  render() {
    return (
      <div className="explain-container">
        <h2 className="mt-3 mb-5" style={{ color: "#002142" }}>
          How it works
        </h2>
        <div
          className="mt-4 row actual-info"
          style={{ width: "80%", margin: "auto" }}
        >
          <div>
            <img src={watch} style={{ height: "200px" }}></img>
            <br />
            <div className="mt-2">
              <b>Record</b> an activity
            </div>
          </div>
          <span className="long-arrow-right"></span>
          <div>
            <img src={logo} style={{ height: "200px" }}></img>
            <br />
            <div className="mt-2">Sync with Strava</div>
          </div>
          <span className="long-arrow-right"></span>
          <div>
            <img src={sheet} style={{ height: "200px" }}></img>
            <br />
            <div className="mt-2" style={{ maxWidth: "200px" }}>
              <b>TbirdSync</b> automatically records the activity in your
              training log
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExplainationWeb;
