import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import MagicSliderDots from "react-magic-slider-dots";
import "react-magic-slider-dots/dist/magic-dots.css";

import logo from "../../images/strava_logo.png";
import sheet from "../../images/sheet_icon.png";
import watch from "../../images/watch.png";

import "../../style.css";

class ExplainationMobile extends Component {
  state = {};
  render() {
    const settings = {
      dots: true,
      arrows: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      appendDots: (dots) => {
        return <MagicSliderDots dots={dots} numDotsToShow={4} dotWidth={30} />;
      },
    };

    return (
      <div className="explain-container">
        <h2 className="mt-3 mb-5" style={{ color: "#002142" }}>
          How it works
        </h2>
        <Slider {...settings}>
          <div className="explain-item">
            <img src={watch} style={{ height: "150px" }}></img>
            <br />
            <div className="mt-2">
              <b>Record</b> an activity
            </div>
          </div>
          <div className="explain-item">
            <img src={logo} style={{ height: "150px" }}></img>
            <br />
            <div className="mt-2">Sync with Strava</div>
          </div>
          <div className="explain-item">
            <img src={sheet} style={{ height: "150px" }}></img>
            <br />
            <div className="mt-2" style={{ maxWidth: "150px" }}>
              <b>TbirdSync</b> automatically records the activity in your
              training log
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}

export default ExplainationMobile;
