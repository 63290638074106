import React, { Component } from "react";
import MediaQuery from "react-responsive";

import "../style.css";

class Navbar extends Component {
  state = {};
  render() {
    return (
      <nav class="navbar fixed-top navbar-custom" role="navigation">
        <MediaQuery minWidth={768}>
          <div class="navbar-nav">
            <div class="navbar-header">
              <span className="navbar-brand branding">TbirdSync</span>
            </div>
          </div>
        </MediaQuery>

        <MediaQuery maxWidth={767}>
          <div class="navbar-header">
            <span className="navbar-brand branding">TbirdSync</span>
          </div>
        </MediaQuery>
      </nav>
    );
  }
}

export default Navbar;
