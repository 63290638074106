import React, { Component } from "react";
import logo from "../../images/strava_logo.png";
import sheet from "../../images/sheet_icon.png";
import watch from "../../images/watch.png";
import Footer from "../footer";
import MediaQuery from "react-responsive";
import ExplainationWeb from "./explainationWeb";

import "../../style.css";
import ExplainationMobile from "./explainationMobile";

class Info extends Component {
  state = {};
  render() {
    return (
      <div className="info-wrapper">
        <div className="info-container">
          <div>
            <MediaQuery maxWidth={767}>
              <h1> Training logs made easy </h1>
            </MediaQuery>
            <MediaQuery minWidth={768}>
              <h2> Training logs made easy </h2>
            </MediaQuery>{" "}
          </div>
          <MediaQuery minWidth={768}>
            <span className="info-text">
              Automatically update your spreadsheet everytime you upload an
              activity to Strava.
            </span>
          </MediaQuery>
          <div className="mt-4">
            <a
              class="btn info-button btn-lg"
              href="https://www.tbirdsync.com/#/sign-in"
              role="button"
            >
              Sign Up
            </a>
          </div>
        </div>
        <MediaQuery minWidth={768}>
          <ExplainationWeb />
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          <ExplainationMobile />
        </MediaQuery>
        <Footer />
      </div>
    );
  }
}

export default Info;
