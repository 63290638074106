import React, { Component } from "react";
import { HashRouter, Route, Switch, Link, Redirect } from "react-router-dom";
import SignIn from "./components/SignUpPage/signin";
import Landing from "./components/HomePage/landing";
import Goog from "./components/SignUpPage/goog";
import Sheets from "./components/SignUpPage/sheets"
import Policy from "./components/PolicyAndTerms/policy"
import Term from "./components/PolicyAndTerms/term"

class App extends Component {
  render() {
    return (
      // <React.Fragment>
      //   <Navbar />
      //   <About />
      //   {/* <Technology /> */}
      //   <Projects projects={projects}></Projects>
      //   <Footer />
      // </React.Fragment>
      <HashRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return (
                <Redirect to="/site" />
              )
            }}
          />
          <Route path="/site" component={Landing} />
          <Route path="/sign-in" component={SignIn} />
          <Route path="/goog" component={Goog} />
          <Route path="/drive" component={Sheets} />
          <Route path="/policy" component={Policy} />
          <Route path="/terms" component={Term} />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
